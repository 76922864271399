<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Bolsas de proyectos'">
      <template v-slot:actions>
        <v-btn v-show="permissionsBannerActions.create.includes(user.role)" @click="handleOpenDialogCreateProjectBag()">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </Banner>

    <!-- Data table projectbags -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="$store.state.projectBags.projectBags"
        :totalItems="$store.state.projectBags.totalCountProjectBags"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getProjectBags"
      >
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/bolsas/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          {{ currency(item.amount, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.amount_invested`]="{ item }">
          {{ currency(item.amount_invested, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.amount_repaid`]="{ item }">
          {{ currency(item.amount_repaid, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog create projectbag -->
    <DialogBox :model="dialogCreateProjectBag">
      <template slot="toolbar">
        <span>Crear bolsa</span>
      </template>
      <template slot="content">
        <v-form ref="formCreateProjectBag" v-model="formCreateProjectBagValid">
          <v-text-field v-model="newProjectBag.name" label="Nombre" :rules="formRules.textRules" />
          <v-textarea v-model="newProjectBag.description" label="Descripción" rows="1" auto-grow :rules="formRules.textRules" />
          <v-autocomplete
            v-model="newProjectBag.partner_id"
            :items="partners"
            item-text="name"
            item-value="id"
            :rules="[(v) => v != '' || 'Campo obligatorio']"
            label="Partner"
          />
          <DatePicker
            :parent_model="newProjectBag.finish_date"
            label="Fecha de finalización"
            :required="true"
            :min="dateNowFormated()"
            @change="newProjectBag.finish_date = $event"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogCreateProjectBag', 'formCreateProjectBag')">Cerrar</v-btn>
        <v-btn text @click="create()">Crear</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";
import * as currency from "currency.js";

export default {
  name: "ProjectBags",
  components: {
    Banner,
    DataTable,
    DialogBox,
    DatePicker
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      // Banner actions
      permissionsBannerActions: {
        create: ["SUPERADMIN", "ADMIN"]
      },

      // ProjectBags datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Nombre", value: "name", sortable: false },
        { text: "País", value: "country.name", sortable: false },
        { text: "partner", value: "partner.name", sortable: false },
        { text: "Cantidad solicitada", value: "amount", sortable: false },
        { text: "Cantidad invertida", value: "amount_invested", sortable: false },
        { text: "Cantidad devuelta", value: "amount_repaid", sortable: false },
        { text: "Estado", value: "status", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],

      // Filters variables
      elasticData: {},

      // Dialogs
      dialogCreateProjectBag: false,
      formCreateProjectBagValid: false,

      // Fields of projectbag
      newProjectBag: {
        name: "",
        description: "",
        partner_id: "",
        finish_date: ""
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      partners: (state) => state.partners.partners
    }),
    currency() {
      return currency;
    }
  },
  async created() {
    await this.getProjectBags({ size: 10, sort: { created_at: "DESC" } });
  },
  methods: {
    ...mapActions({
      getProjectBags: "projectBags/getProjectBags"
    }),

    async handlePrevIcon(elasticData) {
      this.elasticData = elasticData;
      await this.getProjectBags(elasticData);
    },

    async handleNextIcon(elasticData) {
      this.elasticData = elasticData;
      await this.getProjectBags(elasticData);
    },

    async handleSearch(elasticData) {
      this.elasticData = elasticData;
      await this.getProjectBags(elasticData);
    },

    // Create project bag
    async create() {
      if (this.$refs.formCreateProjectBag.validate()) {
        this.newProjectBag.finish_date = this.formatDateToISO8601(this.newProjectBag.finish_date);
        await this.$store.dispatch("projectBags/createProjectBag", this.newProjectBag);
        await this.getProjectBags({ size: 10, sort: { created_at: "DESC" } });
        this.closeDialog("dialogCreateProjectBag", "formCreateProjectBag");
      }
    },

    async handleOpenDialogCreateProjectBag() {
      await this.$store.dispatch("partners/getPartners", { size: 10, sort: { created_at: "DESC" } });
      this.dialogCreateProjectBag = true;
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.newProjectBag = this.$options.data().newProjectBag;
    }
  }
};
</script>
